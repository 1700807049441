import React, { useState, useEffect } from "react";
import Appbar from "./components/Appbar";
import Documents from "./components/screens/documents";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./components/AppThemeCustom";
import "./App.css";
import "react-phone-number-input/style.css";
import { SEND_OTP } from "./utils/constants";
import request from "./utils/request";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {validateEmail } from "./utils/validation";
import { FormHelperText } from "@mui/material";
import { useTranslation } from "react-i18next";
function App() {
  const [otp, setOtp] = useState("");
  const [checked, setChecked] = useState(false);
  const [unfilledResponse, setUnfilledResponse] = useState({});
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("")
  const [verified, setVerified] = useState(false);
  const [flag, setFlag] = useState(false);
  const [flag1, setFlag1] = useState(false);
  const [openModal, setOpenModal] = useState(true);
  const [resendMessage, setResendMessage] = useState("");
  const [email , setEmail] = useState("");
  const [timer, setTimer] = useState(600); // 10 minutes in seconds
  const [isEmailFilled, setIsEmailFilled] = useState(false);
  let key = window.location.pathname.split("/");
  let cachedOtp = localStorage.getItem("otp");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const { i18n, t } = useTranslation();

  // const handleCloseModal = () => {
  //   setOpenModal(false);
  // };

  const handleOtpChange = (e) => {
    console.log("otp", e.target.value)
    if(e.target.value !== null){
      setOtp(e.target.value);
    }else{
      setOtp("");
    }
  };

  const handleOtpSubmit = async () => {
    setVerified(true);
    localStorage.setItem("otp", otp);
  };

  const handleCachedOtpSubmit = () => {
    setVerified(true);
    setFlag1(true);
  }

  if(cachedOtp !== null && !flag1){
    handleCachedOtpSubmit();
  }

  const handleInputChange = (e) => {
    setEmail(e.target.value);
    setError("");
    if(validateEmail(e.target.value)){
      setEmailError("Email is not in correct format")
    }else{
      setEmailError("");
    }
  };

  const handleEmailSubmit = () => {
    setIsEmailFilled(true);
  };


  const handleOtpResend = async () => {
    try{
        let payloadData = {
          "action":"resend",
          "email": email,
          "category": email !== "" ? "" : "CROSS_BORDER"
        }
        setIsEmailFilled(true);
        const response = await fetch(
          `${process.env.REACT_APP_CHAIPORT_BACKEND_API_URL}/api/merchant/kyc/${key[key.length - 1]}/send-otp?action=resend`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payloadData)
          }
        );
        let data = await response.json();
        console.log("resend", data)
        if(data.status_code === '2000'){
          setResendMessage(data.message);
          setTimer(600);
        }else{
          setResendMessage(data.message);
          window.location.reload();
        }
        
        
        // Handle response accordingly
    } catch (error) {
      setError("Failed to resend OTP. Please try again.");
    }
  };

  useEffect(() => {
    const timerId = setInterval(() => {
      if (timer > 0) {
        setTimer((prevTimer) => prevTimer - 1);
      }
    }, 1000);

    // Clear the interval when component unmounts or when the timer reaches 0
    return () => clearInterval(timerId);
  }, [timer]);


useEffect(() => {
  if(urlParams.get('category') === "CROSS_BORDER" || isEmailFilled){
    if(!flag && cachedOtp === null){
      setFlag(true);
      let payloadData = {
        "action":"",
        "email": email,
        "category": email !== "" ? "" : "CROSS_BORDER"
      }
      request
      .post(SEND_OTP, payloadData, key[key.length - 1])
      .then((res) => {
        console.log(res);
        if(res.status !== 200){
          setError(res.data.message);
          setEmail("");
          setIsEmailFilled(false);
        }
      })
    }
  }

},[key,flag])


  return (
    <ThemeProvider theme={theme}>

    {verified ? (
        <>
          <Appbar setChecked={setChecked} setUnfilledResponse={setUnfilledResponse} checked={checked} otp={cachedOtp !== null ? cachedOtp : otp} unfilledResponse={unfilledResponse} setVerified={setVerified} />
          <Documents otp={cachedOtp !== null ? cachedOtp : otp} setVerified={setVerified} setResendMessage={setResendMessage} unfilledResponse={unfilledResponse} checked={checked} />
        </>
      ) : (
        <>
        <Appbar />
        <Modal
        open={openModal}
        // onClose={handleCloseModal}
        aria-labelledby="otp-modal"
        aria-describedby="otp-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: window.innerWidth > 767 ? 400 : 300,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
        {urlParams.get('category') === "CROSS_BORDER" || isEmailFilled ?
        <>
          <Typography id="otp-modal" variant="h6" component="h2">
          {t("OTP Verification")}
          </Typography>
          <Typography variant="p" component="p" style={{ fontSize:"12px" , color:"green" , fontWeight:"600"}}> {t("An OTP has been sent to your registered Email Address. Please enter the OTP to proceed")} - <span style={{ color: "red"}}> {t("Remaining time")}:{" "}
            {Math.floor(timer / 60)}:{(timer % 60).toString().padStart(2, "0")} </span></Typography>
          <TextField
            id="otp-input"
            label={t("Enter OTP")}
            variant="outlined"
            value={otp}
            onChange={handleOtpChange}
            fullWidth
          />
          <Button onClick={handleOtpSubmit} variant="contained" sx={{ mt: 2 }}>
            {t("Submit")}
          </Button>
          <Button onClick={handleOtpResend} variant="outlined" sx={{ mt: 2, ml:1 }}>
            {t("Resend OTP")}
          </Button>
        </>
        : key[key.length - 1] === undefined || key[key.length - 1] === "" ?
        <Typography variant="h6" component="h6" style={{ textAlign: "center"}}>
            {t("Invalid KYC ref")}</Typography>
        :
        <>
          <Typography id="otp-modal" variant="h6" component="h2">
            {t("Email Verification")}
          </Typography>
          <Typography variant="p" component="p" style={{ fontSize:"12px" , color:"green" , fontWeight:"600"}}>
            {t("Please enter the Email to proceed")}</Typography>
          <TextField
            id="email-input"
            type="email"
            label="Enter Email"
            variant="outlined"
            value={email}
            onChange={handleInputChange}
            fullWidth
          />
          <FormHelperText style={{ color: "red", fontSize: "12px" }}>
            {emailError}
          </FormHelperText>
          <Button onClick={handleEmailSubmit} variant="contained" sx={{ mt: 2 }}>
            {t("Submit")}
          </Button>
          
        </>
        }
        
          
          {error && <p className="error" style={{ color: "red" }}>{error}</p>}
          {resendMessage !== "" ? <p className="success">{resendMessage}</p> : ""}
        </Box>
      </Modal>
      </>
        
      )}  
    </ThemeProvider>
  );
}

export default App;
