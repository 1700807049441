import React, { useState } from "react";
import { Grid, Typography, Alert } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from "@mui/material/styles";
import CustomButton from "../../CustomButton";
import ButtonContainer from "../../ButtonContainer";
import Check from "../../../images/check.svg";
import Download from "../../../images/download.svg";
import request from "../../../utils/request";
import { SUBMIT_KYC } from "../../../utils/constants";
import moment from "moment";
import { useTranslation } from "react-i18next";
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const DetailDiv = styled(Grid)(({ theme }) => ({
  backgroundColor: "#F8F8F8",
  border: "0.5px dotted #707070",
  borderRadius: "15px",
  padding: "30px",
  color: "#B3AFAF",
  maxWidth: "100%",
}));

const FilePreviewDiv = styled(Grid)(({ theme }) => ({
  backgroundColor: "#F8F8F8",
  border: "0.5px #707070",
  borderRadius: "15px",
  padding: "15px",
  color: "#B3AFAF",
  display:"flex",
  justifyContent:"space-between"
}));


const Step3 = (props) => {
  const [responseMessage, setResponseMessage] = useState("");
  const { i18n, t } = useTranslation();
  const [isError, setIsError] = useState(false);
  const { setValue, setPage, states, environment, setPageCursor, pageCursor, isEditAllowed, category } = props;
  setPageCursor(pageCursor >= 3 ? pageCursor : 3);
  const [loading, setLoading] = useState(false);
  // const [numPages, setNumPages] = useState(null);
  // const [pageNumber, setPageNumber] = useState(1);
  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  //   setPageNumber(1);
  // }
  
  

  const handleContinue = () => {
    setLoading(true);
    let copy_fields = states.field_details;
    copy_fields.map((val)=> {
      if(val.key === "birth_date"){
        val.value = val.value !== "" ? moment(val.value).format("YYYYMMDD") : "";
      }
    })
    let key = window.location.pathname.split("/");
    request
      .post(
        SUBMIT_KYC,
        {
          name: states.fullName,
          phone_number: states.phoneNumber,
          email_address: states.email,
          document_details: states.document_details.filter((doc) => (doc.link !== "" || doc.multi_document_links.length > 0) ),
          field_details : copy_fields,
          environment : environment,
          country_code: states.countryCode,
          payment_channel: states.payment_channel,
          category: category
        },
        key[key.length - 1]
      )
      .then((res) => {
        if(res.status === 200) {
          if(res.data && res.data.payment_channel_response && res.data.payment_channel_response.is_success){
            setResponseMessage("");
            setValue(3);
            setPage(3);
          } else if(category === "CROSS_BORDER"){
            setResponseMessage(res.data.message);
          }
          else{
            setResponseMessage(res.data.payment_channel_response.statusChannelReason);
          }
          
        } else {
            setResponseMessage(res.data.message);
            setIsError(true);
        }
        window.scrollTo(0, 0)
      })
      .catch((err) => {
        console.log("Error", err);
        setResponseMessage(err);
        window.scrollTo(0, 0);
      }).finally(() => {
        // Set loading state to false when submission completes (whether successful or failed)
        setLoading(false);
      });
  };
  const handleBack = () => {
    setValue(1);
  };

  return (
    <>
    {loading && <CircularProgress style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      {responseMessage !== "" && (
        <Alert
          style={{ marginBottom: 10}}
          severity= {isError ? "error" : "success"} 
          onClose={() => {
            setResponseMessage("");
          }}
        >
          {t(responseMessage)}
        </Alert>
      )}
      <DetailDiv container xs={12}>

      

        {/* <Grid container>
          <Grid item xs={5}>
            <Typography variant="p" component="p" style={{ color: "#bdbdbd" }}>
              Name:
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="p" component="p">
              {states.fullName}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={5}>
            <Typography variant="p" component="p" style={{ color: "#bdbdbd" }}>
              Email ID:
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography
              variant="p"
              component="p"
              style={{ overflowWrap: "break-word" }}
            >
              {states.email}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={5}>
            <Typography variant="p" component="p" style={{ color: "#bdbdbd" }}>
              Phone Number:
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="p" component="p">
              {states.phoneNumber}
            </Typography>
          </Grid>
        </Grid> */}

      {states.field_details.map((field) => (
        
        <React.Fragment key={field.uuid}>
        <Grid container style={{ marginBottom: "1rem" }}>
          <Grid item xs={5}>
            <Typography variant="p" component="p" style={{ color: "#bdbdbd" }}>
            {t(field.key)}:
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="p" component="p" style={{ overflowWrap: "break-word" }}>
              {field.display_title === "Password" && !isEditAllowed ? <input type="password" value={field.value} style={{ borderTop: "hidden",
              borderRight: "hidden",
              borderLeft: "hidden",
              borderBottom: "hidden", backgroundColor:"#F8F8F8" }} disabled/> : field.value}
            </Typography>
          </Grid>
        </Grid>
        </React.Fragment>

      ))}

      </DetailDiv>
      <Grid container style={{ margin: "3rem 0" }}>
        {states.document_details.map((doc) => {
          let fileName1, fileName2;
          if(!doc["multiple_upload"]){
            fileName1 = doc["link"].split('/');
            fileName2 = fileName1[fileName1.length - 1];
          }
          console.log(doc)
          return(
          <React.Fragment key={doc.uuid}>
            {(doc.link !== "" || doc.multi_document_links.length > 0) && <>
            <Grid
              container
              justifyContent="space-between"
              style={{ marginTop: "2.5rem" }}
            >
              <Grid item xs={5}>
                <Typography variant="p" component="p" style={{ color: "#bdbdbd" }}>
                  {t(doc.key)}:
                </Typography>
                
              </Grid>
              <Grid item xs={12}>
                <Typography variant="p" component="p" style={{ color: "#bdbdbd", fontSize: "12px" }}>
                  {t(doc.description)}
                </Typography>
                
              </Grid>

              <Grid
                item
                xs={7}
                style={{ marginBottom: "1rem", color: "#B3AFAF" }}
              >
                <Typography>{doc.name}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Grid container>
                  {/* <Grid
                    item
                    xs={6}
                    flexDirection={"row"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                  >
                    <AccessTimeIcon
                      style={{ fontSize: 14, color: "#F4AA74" }}
                    />
                    <Typography style={{ fontSize: 14, color: "#F4AA74" }}>
                      In Process
                    </Typography>
                  </Grid> */}
                  <Grid
                    item
                    xs={12}
                    flexDirection={"row"}
                    display={"flex"}
                    justifyContent={"flex-end"}
                  >
                    <Typography
                      style={{
                        fontSize: 14,
                        color: "#32B34A",
                        paddingRight: "10px",
                      }}
                    >
                      {t("Added")}
                    </Typography>
                    <img
                      src={Check}
                      style={{ width: "auto", height: "20px" }}
                      alt="check"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {
              doc["multiple_upload"] ?
              <FilePreviewDiv container>
              <Grid item xs={12}
              flexDirection={"row"}
              display={"flex"}
              justifyContent={"flex-start"}
              >
                <Typography style={{ overflowWrap:"anywhere"}}>
                  {doc["multi_document_links"].length > 0 ?
                    doc["multi_document_links"].map((file, index) => {
                      let file1 = file.split("/");
                      let file2 = file1[file1.length-1]
                      
                    return(
                      <>
                      {file2 + ((index+1 !== doc["multi_document_links"].length) ? "   |   " : "") 
                    
                      }</> ) }) : ""}
                  <br/>
                  {doc["multi_document_links"].length > 0  ? 
                  doc["multi_document_links"].map((file) => file.split(".")[file.split(".").length-1] !== "pdf" ? 
                  <>
                    <div style={{ position: "relative", display: "inline-block" , marginRight: "25px" }}>
                    <img src={file} alt={"file"} width="150" style={{ border: "1px solid #bdbdbd", marginTop: "10px" }} />
                    <a href={file} target="_blank" rel="noreferrer" download style={{ position: "absolute", top: 5, right: -20 }}>
                      <img src={Download} style={{ color: "black" }} />
                    </a>
                  </div>
                  </>
                   :
                   <>
                    <div style={{ position: "relative", display: "block" , margin: "10px 0px " }}>
                    {file.split("/")[file.split("/").length-1]}
                    <a href={file} target="_blank" rel="noreferrer" download style={{ position: "absolute", margin: "3px 0px 10px 10px" }}>
                      <img src={Download} style={{ color: "black" }} />
                    </a>
                  </div>
                  </>)
                 : ""}
                   
                </Typography>
              </Grid>
              {/* <Grid
                item
                xs={2}
                flexDirection={"row"}
                display={"flex"}
                justifyContent={"flex-end"}
              >
                {doc["multi_document_links"] && doc["multi_document_links"].map((link, index) => (
                  <a key={index} href={link} target="_blank" download>
                    <img src={Download} style={{ color: "black" }} />
                  </a>
                ))}
              </Grid> */}
            </FilePreviewDiv>

              :
              <FilePreviewDiv container>
              <Grid item xs={10}
              flexDirection={"row"}
              display={"flex"}
              justifyContent={"flex-start"}
              >
                <Typography style={{ overflowWrap:"anywhere"}}>
                  {fileName2 ? fileName2 : doc.display_title}
                  <br/>
                  {doc.link !== "" ? doc.link.split(".")[doc.link.split(".").length-1] !== "pdf" ? <img src={doc.link} alt={doc.fileName} width="150" style={{ border: "1px solid #bdbdbd", marginTop:"5px"}} /> :
                //    <Document file={{ url : doc.link}} onLoadSuccess={onDocumentLoadSuccess} options={{ workerSrc: "pdf.worker.js" }}>
                //    <Page height="150" pageNumber={pageNumber} />
                //  </Document>
                 ""
                 : ""}
                   
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                flexDirection={"row"}
                display={"flex"}
                justifyContent={"flex-end"}
              >
                <a href={doc.link} target="_blank" rel="noreferrer" download>
                  <img src={Download} style={{ color: "black" }} />
                </a>
              </Grid>
            </FilePreviewDiv>
            }
           </> }
          </React.Fragment>)
        })}
      </Grid>
      <ButtonContainer style={{ textAlign: "left" }}>
        {<CustomButton
          text={t("Submit")}
          variant={"contained"}
          disabled={!isEditAllowed}
          onClick={handleContinue}
        />}
        <CustomButton text={t("Back")} variant={"text"} onClick={handleBack} />
      </ButtonContainer>
    </>
  );
};

export default Step3;
