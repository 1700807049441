import React from "react";
import {
  InputBase,
  alpha,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import styled from "@emotion/styled";
import TooltipImg from "../images/tooltip.jpg";
import { useTranslation } from "react-i18next";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: "1rem",
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const CurrencyInputWrapper = styled.div({
  display: "flex",
  alignItems: "center",
  marginTop: "24px"
});

const CurrencySymbol = styled.div(({ theme }) => ({
  paddingRight: theme.spacing(1), // Adjust spacing as needed
  backgroundColor: "#fcfcfb",
  padding: "9.5px 5px",
  borderTopLeftRadius:"5px",
  borderBottomLeftRadius:"5px",
  borderTop: "1px solid #ced4da",
  borderLeft:"1px solid #ced4da",
  borderBottom:"1px solid #ced4da",
  width: "50px",
  height:"24px",
  fontSize:"17px",
  textAlign:"center"
}));

export default function CustomInput(props) {
  const {
    id,
    variant = "standard",
    type,
    name,
    value,
    currency,
    onChange,
    onBlur,
    onFocus,
    disabled,
    label,
    maxLength,
    minLength,
    error,
    tooltip,
    errorText,
    required,
  } = props;
  var today = new Date();
  const { i18n, t } = useTranslation();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;

  const handleNumberInputChange = (e) => {
    const { value } = e.target;
    // Allow only numbers and '-' character
    if (/^-?\d*(?:-\d*)*$/.test(value)) {
      onChange(e);
    }
  };

  return (
    <div style={{ margin: "2rem 0", fontSize: "1.3rem" }}>
      <FormControl variant="standard" fullWidth>
        <InputLabel shrink htmlFor="bootstrap-input" style={{ display: "flex", justifyContent:"center", alignItems: "end"}}>
        {required ? 
           <><span class="text-danger" style={{ fontSize: "25px"}}>* </span>  <span style={{ fontWeight: 600, padding:"5px"}}>{t(name)}</span> </>
          : <span style={{ fontWeight: 600, padding:"5px"}}>{t(name)} </span> }
          {tooltip !== "" && tooltip !== undefined && tooltip !== null ? 
          (<span>
            <Tooltip title={t(tooltip)}>
              <img src={TooltipImg} alt={"info logo"} width="25px" />
            </Tooltip>
          </span>): ""}
        </InputLabel>
        { type === 'date' ?
        <BootstrapInput
          id={id}
          fullWidth
          variant={variant}
          placeholder="Type here"
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
          error={error}
          required={required}
          inputProps={{ max: today, min: "1900-01-01" }}
          // helperText={(error && errorText)}
        />
        
        :
        type === 'currency' ?
        (<CurrencyInputWrapper>
          <CurrencySymbol>{currency}</CurrencySymbol>
          <BootstrapInput
            id={id}
            fullWidth
            variant={variant}
            placeholder="Type here"
            type={'number'}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            disabled={disabled}
            error={error}
            required={required}
            inputProps={{ maxLength: maxLength, minLength: minLength, style:{ borderRadius: "0"} }}
            // helperText={(error && errorText)}
          />
        </CurrencyInputWrapper>)
        : 
        type === "number" ? 
        <BootstrapInput
          id={id}
          fullWidth
          variant={variant}
          placeholder="Type here"
          type={'text'}
          name={name}
          value={value}
          onChange={handleNumberInputChange}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
          error={error}
          required={required}
          inputProps={{ maxLength: maxLength, minLength: minLength }}
          // helperText={(error && errorText)}
        />
        :
        <BootstrapInput
          id={id}
          fullWidth
          variant={variant}
          placeholder="Type here"
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
          error={error}
          required={required}
          inputProps={{ maxLength: maxLength, minLength: minLength }}
          // helperText={(error && errorText)}
        />
        }
        <FormHelperText style={{ color: "red", fontSize: "12px" }}>
          {error && errorText}
        </FormHelperText>
      </FormControl>
    </div>
    // <TextField
    //     id={id}
    //     fullWidth
    //     variant={variant}
    //     label={label}
    //     type={type}
    //     name={name}
    //     value={value}
    //     onChange={onChange}
    //     onBlur={onBlur}
    //     onFocus={onFocus}
    //     error={error}
    //     helperText={(error && errorText)}
    // />
  );
}
