import React, { useState, useEffect } from "react";
import { CircularProgress, Grid, Box, Alert } from "@mui/material";
import Common from "./Common";
import { makeStyles, createStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import MainContainer from "../../MainContainer";
import CustomTab from "../../CustomTab";
import One from "../../../images/one.svg";
import Two from "../../../images/two.svg";
import Three from "../../../images/three.svg";
import Four from "../../../images/four.svg";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import UploadSuccess from "./UploadSucess";
import { validateEmail, validateRequired, validator } from "../../../utils/validation";
import { phoneNumberFormator } from "../../../utils/formatter";
import request from "../../../utils/request";
import { GET_KYC_SUBMISSION } from "../../../utils/constants";
import moment from "moment";

const Image = styled("img")(({ theme }) => ({
  maxWidth: "80%",
  marginTop: "10px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const ImageDiv = styled("div")(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.up("md")]: {
    width: "70%",
  },
}));

const useStyles = makeStyles((theme) =>
  createStyles({
    outerBox:{
      height: "100vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    imageBox:{
      margin: "0 20px",
      [theme.breakpoints.up("sm")]: {
        position: "fixed", 
        display: "flex", 
        justifyContent: "end", 
        alignItems:"end",
        zIndex:"-1"
      }, 
      

    }
  })
);

const Documents = (props) => {
  const {otp, setVerified, setResendMessage, unfilledResponse, checked} = props;
  //console.log(unfilledResponse.data && unfilledResponse.data.content.document_details, checked);
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(0);
  const [pageCursor, setPageCursor] = useState(1);
  const [disablestep2, setDisableStep2] = useState(true);
  const [disablestep1, setDisableStep1] = useState(true);
  const [responseMessage, setResponseMessage] = useState("");
  const [isEditAllowed, setIsEditAllowed] = useState(true);
  const [loading, setLoading] = useState(false);
  const [environment, setEnvironment] = useState(params.environment ? params.environment : "live" );
  const [psp, setPsp] = useState('');
  const [category, setCategory] = useState("");
  const [urlvalidation, setUrlValidation] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [initalState, setInitalStates] = useState({
    countryCode: "",
    fullName: "",
    email: "",
    phoneNumber: "",
    payment_channel:"",
    document_details: [],
    field_details: [],
    filtered_document_details:[],
  });
  let mutipleFiles = [];
  const [errors, setErrors] = useState({
    // fullName: false,
    // email: false,
    // phoneNumber: false,
    field_details: []
  });
  
  useEffect(() => {
    setLoading(true);
    if(checked && unfilledResponse.data){
      setLoading(false);
      setInitalStates({...initalState, filtered_document_details: unfilledResponse.data?.content?.document_details})
    }
  }, [checked, unfilledResponse.data])

  useEffect(() => {
    setLoading(true);
    if (!checked) {
      setLoading(false);
    }
  }, [checked]);
  

  const handleChange = (e) => {
    if(psp === "GBPRIMEPAY"){
      if(e.target.name === "merchant_type"){
        if(e.target.value === "I"){
          let flag_all_upload = true;
          //console.log(initalState.document_details)
          let document_field = initalState.document_details
          document_field.map((val)=> {
            if(val.key === "other_document"){
              val.is_mandatory = true;
              val.style = ""
            }
            else if(val.key === "additional_document"){
              val.is_mandatory = false;
              val.style = "disabled"
            }
            else if(val.key === "company_registration"){
              val.is_mandatory = false;
            }
            if(val.is_mandatory){
              if(val.link === ""){
                flag_all_upload = false;
              }
            }

            if(flag_all_upload){
              setDisableStep2(false);
            }
            else{
              setDisableStep2(true);
            }


          })

          setInitalStates({...initalState, document_details: document_field})
        }
        else{
          setDisableStep2(true);
          let flag_all_upload = true;
          let document_field = initalState.document_details
          document_field.map((val)=> {
            if(val.key === "additional_document"){
              val.is_mandatory = true;
              val.style = "";
            }
            else if(val.key === "company_registration"){
              val.is_mandatory = true;
            }
            else if(val.key === "other_document"){
              val.is_mandatory = false;
              val.style = "disabled"
            }

            if(val.is_mandatory){
              if(val.link === ""){
                flag_all_upload = false;
              }
            }

            if(flag_all_upload){
              setDisableStep2(false);
            }
            else{
              setDisableStep2(true);
            }

          })

          setInitalStates({...initalState, document_details: document_field})

        }
      }
    }
    //console.log(initalState.document_details);
    let { name, value } = e.target;
    let flag = false;
    let g = false;
    if (initalState.field_details.length) {
      let new_field = initalState.field_details;
      let new_error = errors.field_details;
      new_field.forEach((field) => {
        if (field.key === name) {
          field.value = value;
          let findElement = new_error.map(object => object.key).indexOf(field.key);
          if(findElement === -1 || findElement === undefined){
            if(field.is_mandatory){
              new_error.push({
                key: field.key,
                value: validator(field.field_type, value) || validateRequired(value)
              })
              
            }
            else{
              new_error.push({
                key: field.key,
                value: validator(field.field_type, value)
              })
            }
            
          }
          else{
            
            if(field.is_mandatory){
              new_error[findElement].value = validator(field.field_type, value) || validateRequired(value);
              
            }
            else{
              new_error[findElement].value = validator(field.field_type, value) 
            }

          }
        }

        if(field.is_mandatory){
          if(field.value === ""){
            flag = true;
          }
        }
        else if(field.field_type === "url"){
          if(field.value !== ""){
            g = true;
          } 
        }
        else if(field.key === "country"){
          setSelectedCountry(e.target.value);
        }

      });
      
      // new_field.forEach((i) => { 
        
      // })

      new_error.forEach((i) => { 
        if(i.value === true){
            flag = true;
        }  
      })
    
        setDisableStep1(flag);
        setUrlValidation(g);
        
        // setValue(1);
        // setPage(1);
      
      setInitalStates({ ...initalState, field_details: new_field });
      setErrors({ ...errors, field_details: new_error });
    }


    // console.log(value);
    // console.log("name", name);
    // console.log("before", initalState.field_details);
    // setInitalStates((prevState) => ({ ...prevState.field_details, [name]: value }));
    // console.log("after", initalState.field_details)
    // if (value.length === 0) {
    //   setErrors((prevState) => ({ ...prevState, [name]: true }));
    //   setDisableStep1(true);
    // } else if (name === "email" && validateEmail(value)) {
    //   setErrors((prevState) => ({ ...prevState, [name]: true }));
    //   setDisableStep1(true);
    // } else if (name === "phoneNumber" && value.length < 11) {
    //   setErrors((prevState) => ({ ...prevState, [name]: true }));
    //   setDisableStep1(true);
    // } else {
    //   setErrors((prevState) => ({ ...prevState, [name]: false }));
    //   setDisableStep1(false);
    // }
  };

  const handleTab = (newValue) => {
    if (newValue <= page) {
      setValue(newValue);
      if(newValue === 0){
        let copy_fields = initalState.field_details;
        copy_fields.map((val)=> {
        if(val.key === "birth_date"){
          val.value = val.value !== "" ? moment(val.value).format("YYYY-MM-DD") : "";
          setInitalStates({
            ...initalState,
            field_details: copy_fields, 
          });
        }
        })
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    let key = window.location.pathname.split("/");
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    setCategory(urlParams.get('category'));
    const category1 = urlParams.get('category');
    let paymentChannel = (category1 === "" || category1 === null) ? psp : urlParams.get('paymentChannel');
   
    let country = psp === "GBPRIMEPAY" && (category1 === "" || category1 === null) ? "TH" : "GLOBAL";
    request
      .get(GET_KYC_SUBMISSION, key[key.length - 1], country, paymentChannel, environment, category1, otp)
      .then((res) => {
        //console.log(res);
        if (res.status === 200) {
          setIsEditAllowed(true);
          let content = res.data.content;
          let copy_fields = content.field_details;
  
          copy_fields.map((val)=> {
            if(val.key === "birth_date"){
              val.value = val.value !== "" ? moment(val.value).format("YYYY-MM-DD") : "";
            }
          })
          setInitalStates({
            fullName: content.name,
            email: content.email_address,
            phoneNumber: `${content.phone_number}`,
            countryCode: content.country_code,
            document_details: content.document_details,
            field_details: copy_fields,
            payment_channel: content.payment_channel,
          });
          let errarr = [];
          content.field_details.map((val) => 
            errarr.push({key: val.key, value: false})
          )
          setErrors((preverror) => ({ ...preverror, field_details: errarr }))
          setResponseMessage("");
          // if (content.name !== "") {
          //   setDisableStep1(false);
          // }
          // if (content.field_details.every((i) => i.is_mandatory && i.value != "")) {
          //   setDisableStep1(false);
          // }
          let flag = false;
          content.field_details.forEach((i) => { 
            if(i.is_mandatory && i.value === ""){
              flag = true;
            }
          })
          setDisableStep1(flag);
          setLoading(false);

          // if (content.document_details.every((i) => i.link !== "")) {
          //   setDisableStep2(false);
          // }
          let document_flag = false;
          content.document_details.forEach((i) => { 
            if(i.is_mandatory && i.link === ""){
              document_flag = true;
            }
          })
          setDisableStep2(document_flag);

        }
        else if (res.data.status_reason === "MERCHANT_ALREADY_REGISTERED_ON_PSP" && res.data.content.is_edit_allowed === false) {
          let content = res.data.content;
          let copy_fields = content.field_details;
          setIsEditAllowed(false)
          setUrlValidation(true)
          setPage(2);
          setValue(2);
  
          copy_fields.map((val)=> {
            if(val.key === "birth_date"){
              val.value = val.value !== "" ? moment(val.value).format("YYYY-MM-DD") : "";
            }
          })
          setInitalStates({
            fullName: content.name,
            email: content.email_address,
            phoneNumber: `${content.phone_number}`,
            countryCode: content.country_code,
            document_details: content.document_details,
            field_details: copy_fields,
            payment_channel: content.payment_channel,
          });
          let errarr = [];
          content.field_details.map((val) => 
            errarr.push({key: val.key, value: false})
          )
          setErrors((preverror) => ({ ...preverror, field_details: errarr }))
          setResponseMessage(res.data.message);
          // if (content.name !== "") {
          //   setDisableStep1(false);
          // }
          // if (content.field_details.every((i) => i.is_mandatory && i.value != "")) {
          //   setDisableStep1(false);
          // }
          let flag = false;
          content.field_details.forEach((i) => { 
            if(i.is_mandatory && i.value === ""){
              flag = true;
            }
          })
          setDisableStep1(flag);
          setLoading(false);

          // if (content.document_details.every((i) => i.link !== "")) {
          //   setDisableStep2(false);
          // }
          let document_flag = false;
          content.document_details.forEach((i) => { 
            if(i.is_mandatory && i.link === ""){
              document_flag = true;
            }
          })
          setDisableStep2(document_flag);

        }
         else {
          setResponseMessage(res.data.message);
          setLoading(false);
          localStorage.removeItem("otp");
          setVerified(false);
          setResendMessage(res.data.message);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  }, [psp, environment, otp]);

  const handleCallback = () =>{
    //setPsp(childData);
    setValue(0);
    setPage(0);
    setPageCursor(1);
    //console.log(childData);
  }

  const handleDocumentDetails = (link, uuid, fileName, isMultipleUploadAllowed, removeIndex) => {
    if (initalState.filtered_document_details && initalState.filtered_document_details.length && isEditAllowed && checked) {
      let new_doc1 = initalState.filtered_document_details;
      let new_doc = initalState.document_details;
      console.log("new_docs", fileName, uuid, isMultipleUploadAllowed);
      new_doc.forEach((doc) => {
        if (doc.uuid === uuid) {
          if(isMultipleUploadAllowed){
            if (doc.multi_document_links && removeIndex === undefined) {
              // If multi_document_links exists, push the new link
              doc.multi_document_links.push(link);
            } else if(doc.multi_document_links && removeIndex !== undefined) {
              doc.multi_document_links.splice(removeIndex, 1);
            }
            else{
                // If multi_document_links doesn't exist, initialize it as an array with the new link
                doc.multi_document_links = [link];
            }
            doc.fileName = fileName;


          }else{
            doc.link = link;
            doc.fileName = fileName;
          }
        }
      });
      new_doc1.forEach((doc) => {
        if (doc.uuid === uuid) {
          if(isMultipleUploadAllowed){
            if (doc.multi_document_links && removeIndex === undefined) {
              // If multi_document_links exists, push the new link
              doc.multi_document_links.push(link);
            } else if(doc.multi_document_links && removeIndex !== undefined) {
              doc.multi_document_links.splice(removeIndex, 1);
            }
            else{
                // If multi_document_links doesn't exist, initialize it as an array with the new link
                doc.multi_document_links = [link];
            }
            doc.fileName = fileName;


          }else{
            doc.link = link;
            doc.fileName = fileName;
          }
        }
      });
      console.log(new_doc);
      // if (new_doc.every((i) => i.link !== "")) {
      //   setDisableStep2(false);
      // } else {
      //   setDisableStep2(true);
      //   setValue(1);
      //   setPage(1);
      // }
      let document_flag = false;
      new_doc.forEach((i)=>{
        if(i.link === "" && i.is_mandatory && !isMultipleUploadAllowed){
          document_flag = true
        } else if(isMultipleUploadAllowed && i.is_mandatory && i.multi_document_links.length === 0){
          document_flag = true;
        }
      });
      if(document_flag){
        setDisableStep2(document_flag);
        setValue(1);
        setPage(1);
      }
      else{
        setDisableStep2(document_flag);
      }
      setInitalStates({ ...initalState, filtered_document_details: new_doc1, document_details: new_doc });
    }else{
      let new_doc = initalState.document_details;
      console.log("new_docs", fileName, uuid, isMultipleUploadAllowed);
      new_doc.forEach((doc) => {
        if (doc.uuid === uuid) {
          if(isMultipleUploadAllowed){
            if (doc.multi_document_links && removeIndex === undefined) {
              // If multi_document_links exists, push the new link
              doc.multi_document_links.push(link);
            } else if(doc.multi_document_links && removeIndex !== undefined) {
              doc.multi_document_links.splice(removeIndex, 1);
            }
            else{
                // If multi_document_links doesn't exist, initialize it as an array with the new link
                doc.multi_document_links = [link];
            }
            doc.fileName = fileName;


          }else{
            doc.link = link;
            doc.fileName = fileName;
          }
        }
      });
      console.log(new_doc);
      // if (new_doc.every((i) => i.link !== "")) {
      //   setDisableStep2(false);
      // } else {
      //   setDisableStep2(true);
      //   setValue(1);
      //   setPage(1);
      // }
      let document_flag = false;
      new_doc.forEach((i)=>{
        if(i.link === "" && i.is_mandatory && !isMultipleUploadAllowed){
          document_flag = true
        } else if(isMultipleUploadAllowed && i.is_mandatory && i.multi_document_links.length === 0){
          document_flag = true;
        }
      });
      if(document_flag){
        setDisableStep2(document_flag);
        setValue(1);
        setPage(1);
      }
      else{
        setDisableStep2(document_flag);
      }
      setInitalStates({ ...initalState, document_details: new_doc });
    }
  };

  return (
    <>
    {loading ? <div className={classes.outerBox}> <CircularProgress size={40}/></div> :
    <MainContainer>
      {responseMessage !== "" && (
        <Alert
          severity="error"
          onClose={() => {
            setResponseMessage("");
          }}
        >
          {responseMessage}
        </Alert>
      )}
      <Grid container display="flex">
        <Grid item xs={12} md={6} justifyContent="left">
          <Common country={initalState.countryCode} parentCallback = {handleCallback} payment_channel={initalState.payment_channel} pageValue={value} category={category} psp={psp} setPsp={setPsp} />
          {value === 3 ? (
            <UploadSuccess />
          ) : (
            <>
              <CustomTab
                handleTab={handleTab}
                value={value}
                page={page}
                setPage={setPage}
                setPageCursor={setPageCursor}
                pageCursor={pageCursor}
              />
              <ImageDiv>
                {value === 0 && (
                  <Step1
                    handleTab={handleTab}
                    setPage={setPage}
                    pageCursor={pageCursor}
                    setPageCursor={setPageCursor}
                    disabledNext={disablestep1}
                    psp={psp}
                    setValue={setValue}
                    errors={errors}
                    initalState={initalState}
                    handleChange={handleChange}
                    urlvalidation={urlvalidation}
                    setUrlValidation={setUrlValidation}
                    setInitalStates={setInitalStates}
                    responseMessage={responseMessage}
                    field_details= {initalState.field_details}
                    isEditAllowed={isEditAllowed}
                    setSelectedCountry={setSelectedCountry}
                  />
                )}
                {value === 1 && (
                  checked ?
                  <Step2
                    handleTab={handleTab}
                    setPage={setPage}
                    pageCursor={pageCursor}
                    setPageCursor={setPageCursor}
                    disableUpload={disablestep2}
                    setValue={setValue}
                    psp={psp}
                    handleDocumentDetails={handleDocumentDetails}
                    document_details={initalState?.filtered_document_details}
                    isEditAllowed={isEditAllowed}
                  />
                  :
                  <Step2
                    handleTab={handleTab}
                    setPage={setPage}
                    pageCursor={pageCursor}
                    setPageCursor={setPageCursor}
                    disableUpload={disablestep2}
                    setValue={setValue}
                    psp={psp}
                    handleDocumentDetails={handleDocumentDetails}
                    document_details={initalState.document_details}
                    isEditAllowed={isEditAllowed}
                  />
                )}
                {value === 2 && (
                  <Step3
                    handleTab={handleTab}
                    setPage={setPage}
                    pageCursor={pageCursor}
                    setPageCursor={setPageCursor}
                    setValue={setValue}
                    states={initalState}
                    category={category}
                    environment={environment}
                    isEditAllowed={isEditAllowed}
                  />
                )}
              </ImageDiv>
            </>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display="flex"
          justifyContent="right"
          sx={{
            display: { xs: "flex", md: "flex" },
          }}
        >
          <Box className={classes.imageBox}>
            {value === 0 && <Image src={One} alt="one" />}
            {value === 1 && <Image src={Two} alt="two" />}
            {value === 2 && <Image src={Three} alt="three" />}
            {value === 3 && <Image src={Four} alt="four" />}
          </Box>
        </Grid>
      </Grid>
    </MainContainer>
  }</>
  );
};
export default Documents;
