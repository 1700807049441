import Axios from "axios";
Axios.defaults.timeout = 30000;

export default class request {
  static token = "";
  static async get(url, key, country, paymentChannel, environment, category, otp, unfilled_filter) {
    try {
      let response = await Axios.get(
        `${process.env.REACT_APP_CHAIPORT_BACKEND_API_URL}/api/merchant/kyc/${key}/${url}?country=${country}&paymentChannel=${paymentChannel}&environment=${environment}&category=${category}&otp=${otp}&unfilled_filter=${unfilled_filter}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      return response;
    } catch (error) {
      if( error.response){
        return error.response;
      }else{
        return {
          status: 400,
          data: {
            message:  'Sorry! Server is not responding. Please try again later.'
          }
        }
      }
      
    }
  }

  static async post(url, data, key) {
    try {
      let response = await Axios.post(
        `${process.env.REACT_APP_CHAIPORT_BACKEND_API_URL}/api/merchant/kyc/${key}/${url}`,
        data,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      return response;
    } catch (error) {
      if( error.response){
        return error.response;
      }else{
        return {
          status: 400,
          data: {
            message:  'Sorry! Server is not responding. Please try again later.'
          }
        }
      }
    }
  }

  static async postMultipart(url, data, key) {
    try {
      let response = await Axios.post(
        `${process.env.REACT_APP_CHAIPORT_BACKEND_API_URL}/api/merchant/kyc/${key}/${url}`,
        data,
        {
          headers: {
            Accept: "*/*",
            "Content-Type": " multipart/form-data",
          },
        }
      );

      return response;
    } catch (error) {
      if( error.response){
        return error.response;
      }else{
        return {
          status: 400,
          data: {
            message:  'Sorry! Server is not responding. Please try again later.'
          }
        }
      }
    }
  }

  static async patch(url, data, key) {
    try {
      let response = await Axios.patch(
        `${process.env.REACT_APP_CHAIPORT_BACKEND_API_URL}/api/merchant/kyc/${key}/${url}`,
        data,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        }
      );

      return response;
    } catch (error) {
      if( error.response){
        return error.response;
      }else{
        return {
          status: 400,
          data: {
            message:  'Sorry! Server is not responding. Please try again later.'
          }
        }
      }
    }
  }
}
