import React from "react";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    MainContainer: {
      // [theme.breakpoints.up('xs')]: {
      //     padding: '0 0'
      // },
      [theme.breakpoints.up("sm")]: {
        padding: "7rem 7rem 0rem 7rem",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "7rem 1rem 0rem 1rem",
      },
    },
  })
);

export default function MainContainer(props) {
  const classes = useStyles();

  return (
    <div
      data-testid={`${props.testId}-main-container-div`}
      className={classes.MainContainer}
    >
      {props.children}
    </div>
  );
}
