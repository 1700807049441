import React, {useState, useEffect} from "react";
import { Grid, Typography, Box, InputLabel, FormControl, Select, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import Gbpp from "../../../images/gbppicon.svg";
import Baokim from "../../../images/baokim.png";
import { useTranslation } from "react-i18next";

const Country_logo = styled("img")(({ theme }) => ({
  height: "1rem",
  width: "2rem",
  border: "0.5px solid",
}));

const Common = (props) => {
  const { country, payment_channel, pageValue, category, psp, setPsp } = props;
  const { i18n, t } = useTranslation();
  //const [psp, setPsp] = useState(payment_channel);
  const handleChange1 = (event) => {
    setPsp(event.target.value);
    props.parentCallback();
  };

  
  return (
    <div style={{ marginTop: "10px" }}>
      
      <Grid container alignItems="center" spacing={4}>
        <Grid item xs={10} sm={9}>
      {category === "" || category === null ?
        <Typography variant="h6" component="h6">

            <FormControl halfWidth style={{ marginBottom: 20, minWidth: 100 }}>
              <InputLabel id="demo-simple-select-required-label">{t("Select PSP")}</InputLabel>
              <Select
                style={{ height: 50 }}
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                name='psp'
                required={true}
                value={psp}
                label={t('Select PSP')}
                onChange={(e) => handleChange1(e)}
              > 
                <MenuItem value='GBPRIMEPAY'><img style={{ marginRight: 8}} src={Gbpp} alt="gbppicon" width="20px" />  GBPRIMEPAY </MenuItem>
                <MenuItem value='BAOKIM'><img style={{ marginRight: 8}} className="mx-2" src={Baokim} alt="gbppicon" width="20px"  /> BAOKIM</MenuItem>
                
              </Select>
            </FormControl>
            {/* PSP : <img src={Gbpp} alt="gbppicon" width="30px" /> GBPRIMEPAY */}
          </Typography>
          :""}
          { pageValue !== 3 ? <Typography variant="h4" component="h4">
            {t("Upload Your Documents")}
          </Typography> : <Typography variant="h4" component="h4">
            Documents uploaded successfully
          </Typography> }
          <Typography variant="p" component={"p"}>
            { pageValue !== 3 ? t("Upload your KYC document for payment activation") : "" } 
          </Typography>
        </Grid>
        {category === "" || category === null ?
        <Grid item xs={2} sm={3} style={{ marginTop: -100 }}>
          <Box display="flex" justifyContent="flex-end">
            <Typography variant="p" component="h6" gutterBottom>
              {country === "VN"
                ? "Vietnam"
                : country === "TH"
                ? "Thailand"
                : country === "SG"
                ? "Singapore"
                : "Thailand"}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Country_logo
              src={
                country === "VN"
                  ? require("../../../images/vietnam_flag.png")
                  : country === "TH"
                  ? require("../../../images/flag-thai.png")
                  : country === "SG"
                  ? require("../../../images/singapore_flag.png")
                  : require("../../../images/flag-thai.png")
              }
            />
          </Box>
        </Grid>
        :""}
      </Grid>
    </div>
  );
};

export default Common;
