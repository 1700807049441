import { red } from "@mui/material/colors";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

export const customizeTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 992,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: "#1faf4a",
      contrastText: "#fff",
    },
    secondary: {
      main: "#13477a",
      contrastText: "#fff",
    },
    error: red,
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 1,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: "Karla",
    fontSize: "1rem",

    h1: {
      fontWeight: 600,
      fontSize: "3.6rem",
    },
    h2: {
      fontWeight: 600,
      fontSize: "3rem",
    },
    h3: {
      fontWeight: 600,
      fontSize: "2.4rem",
    },
    h4: {
      fontWeight: 800,
      fontSize: "1.8rem",
    },
    h5: {
      fontWeight: 600,
      fontSize: "1.6rem",
    },
    h6: {
      fontWeight: 800,
      fontSize: "1.2rem",
    },
    p: {
      fontWeight: 700,
      lineHeight: "1.5rem",
      color: "#000000",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": ["Karla"],
      },
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: "1rem",
      },
    },

    //Button
    MuiButton: {
      root: {
        borderRadius: "6px",
        lineHeight: "1.5",
        fontWeight: "600",
        marginLeft: "0.5rem",
        "& .MuiSvgIcon-root": {},
      },

      containedSizeLarge: {
        padding: "1rem 2rem",
        fontSize: "1.5rem",
      },

      contained: {
        padding: "0.6rem 2rem",
        fontSize: "1.5rem",
      },
      outlined: {
        padding: "0.6rem 2rem",
        fontSize: "1.5rem",
      },

      outlinedSizeSmall: {
        padding: "0.5rem 2rem",
        fontSize: "1rem",
      },

      outlinedSizeLarge: {
        padding: "1rem 2rem",
        fontSize: "1.5rem",
      },
      containedSizeSmall: {
        padding: "0.5rem 2rem",
        fontSize: "1rem",
      },

      text: {
        padding: "0.6rem 2rem",
        fontSize: "1.5rem",
      },
      textSizeLarge: {
        padding: "1rem 2rem",
        fontSize: "1.5rem",
      },

      textSizeSmall: {
        padding: "0.5rem 2rem",
        fontSize: "1rem",
      },
    },
  },
};

const theme = responsiveFontSizes(createTheme(customizeTheme));

export default theme;
