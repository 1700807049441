import React, {useState} from "react";
import { Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from "react-i18next";




const Tooltip1 = (props) => {
  const {
    item,
  } = props;
  const { i18n, t } = useTranslation();
  const [showTooltip1, setShowTooltip1] = useState(false);
  return (
    <>
          <Typography
          style={{ color: "#000", opacity: "0.6", marginBottom: "1rem" }}
          >
          <div style={{ display: "flex", justifyContent:"start", alignItems: "center" }}>
          {item.is_mandatory && <><span class="text-danger" style={{ fontSize: "25px"}}>* </span></>}  <span style={{ fontWeight: 700}}>{t(item.key)}</span>  {item.tooltip && (
          <Tooltip
            id={item.key}
            disableFocusListener
            title={t(item.tooltip)}
            open={showTooltip1}
            arrow={true}
            placement={'bottom-end'}
            onOpen={() => setShowTooltip1(true)}
            onClose={() => setShowTooltip1(false)}
          ><InfoOutlinedIcon onClick={() => setShowTooltip1(!showTooltip1)}/></Tooltip>)}
          </div>
          
          <Typography variant="p" component="p" style={{ fontSize: "12px" }}>
            {t(item.description)}
          </Typography>
          </Typography>
           </>
    
  );
};

export default Tooltip1;
