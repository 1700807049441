import React, {useState, useEffect} from "react";
import CustomInput from "../../CustomInput";
import CustomButton from "../../CustomButton";
import ButtonContainer from "../../ButtonContainer";
import PhoneInput from "react-phone-input-2";
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import "react-phone-input-2/lib/style.css";
import { InputLabel, FormControl, Select, MenuItem, Tooltip, IconButton, Typography } from "@mui/material";
import TooltipImg from "../../../images/tooltip.jpg";
import { useTranslation } from "react-i18next";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

const Step1 = (props) => {
  const {
    setValue,
    setPage,
    setPageCursor,
    pageCursor,
    errors,
    initalState,
    handleChange,
    disabledNext,
    setInitalStates,
    responseMessage,
    field_details,
    isEditAllowed,
    urlvalidation,
    setSelectedCountry,
    psp
  } = props;

  //   useState(()=>{
  

  //   }, [initalState.phoneNumber])
  setPageCursor(pageCursor >= 1 ? pageCursor : 1);
  const { i18n, t } = useTranslation();
  const [countries, setCountries] = useState([]);
  let allHeadings = new Set();
  field_details.map((item, index) => (
    allHeadings.add(item.section_heading)
  )) 
  const handleContinue = () => {
    setValue(1);
    setPage(1);
    {window.scrollTo(0, 0)}
  };

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        setSelectedCountry(data.userSelectValue);
      });
  }, []);

  return (
    <>
      {Array.from(allHeadings).map((heading, headingIndex) => (
        <div key={headingIndex}>
        {/* Render heading */}
        <Typography variant="h6" component="h6" style={{ textDecoration: "underline" }}>
          {t(heading)}
        </Typography>

      {/* Filter inputs with the current heading and render them */}
      {field_details.filter((item) => item.section_heading === heading).map((item, index) => {
        const originalIndex = field_details.findIndex((fieldItem) => fieldItem.uuid === item.uuid);
        return(
        <div key={originalIndex}>
         
        
        {item.field_type === 'email' ? 
        <CustomInput
        id={item.uuid}
        variant="outlined"
        type='email'
        name={item.key}
        currency={item.currency}
        label={item.display_title }
        maxLength={256}
        tooltip = {item.tooltip}
        required={item.is_mandatory}
        value={initalState.field_details[originalIndex].value}
        onChange={handleChange}
        disabled={initalState.field_details[originalIndex].value !== "" ? false : false }
        error={errors.field_details[originalIndex].value}
        errorText={
          initalState.field_details[originalIndex].value === "" ? t("Required") : t("Your Email is not in proper format")
        }
      />
        :

        item.field_type === 'password' ?

        <CustomInput
        id={item.uuid}
        variant="outlined"
        type='password'
        name={item.key}
        currency={item.currency}
        label={item.display_title }
        maxLength={256}
        minLength={8}
        tooltip = {item.tooltip}
        required={item.is_mandatory}
        value={initalState.field_details[originalIndex].value}
        onChange={handleChange}
        disabled={!isEditAllowed}
        error={errors.field_details[originalIndex].value}
        errorText={
          initalState.field_details[originalIndex].value === "" ? t("Required") : t("Your Password Should contain uppercase, lowercase, numeric , special characters with minimum length 8")
        }
      />
      : 
      item.field_type === 'drop-down' ?

      <FormControl size="small" fullWidth variant="standard">
        
        <InputLabel shrink htmlFor="bootstrap-input" style={{ fontSize: 22, display: "flex", justifyContent:"center", alignItems: "end"}}>
        {item.is_mandatory ?  <><span class="text-danger" style={{ fontSize: "25px"}}>* </span>  <span style={{ fontWeight: "600", padding: "5px"}}>{t(item.key)}</span> </> :  <span style={{ fontWeight: "600", padding:"5px"}}>{t(item.key)} </span> }  
        {item.tooltip !== "" && item.tooltip !== undefined && item.tooltip !== null ?
         <span>
            <Tooltip title={t(item.tooltip)}>
              <img src={TooltipImg} alt={"info icon"} width="25px" /> 
          </Tooltip>
          </span>
        : ""
        }
        
        </InputLabel>
        <Select
          style={{ height: 45 }}
          labelId="demo-simple-select-required-label"
          id="demo-customized-select-native"
          name={item.key}
          required={item.is_mandatory}
          value={initalState.field_details[originalIndex].value}
          label={item.display_title }
          disabled={!isEditAllowed}
          onChange={handleChange}
          input={<BootstrapInput />}
        >
          {item.drop_down_options.options.map((option) => 
          <MenuItem value={option.value}>{option.name}</MenuItem>
          )}
          
  
        </Select>
      </FormControl>
      :
      item.field_type === 'url' ? 
        <CustomInput
        id={item.uuid}
        variant="outlined"
        type='url'
        name={item.key}
        currency={item.currency}
        label={item.display_title }
        maxLength={256}
        tooltip = {item.tooltip}
        value={initalState.field_details[originalIndex].value}
        onChange={handleChange}
        disabled={!isEditAllowed}
        error={errors.field_details[originalIndex].value}
        errorText={
          initalState.field_details[originalIndex].value === "" ? t("required"): t("Your Url is not in proper format")
        }
      /> 
      :
      item.field_type === 'country' ? (
        <div style={{ margin: "2rem 0", fontSize: "1rem" }}>
        <FormControl size="small" fullWidth variant="standard">
        
        <InputLabel shrink htmlFor="bootstrap-input" style={{ fontSize: 22, display: "flex", justifyContent:"center", alignItems: "end"}}>
        {item.is_mandatory ?  <><span class="text-danger" style={{ fontSize: "25px"}}>* </span>  <span style={{ fontWeight: "600", padding: "5px"}}>{t(item.key)}</span> </> :  <span style={{ fontWeight: "600", padding:"5px"}}>{t(item.key)} </span> }  
        {item.tooltip !== "" && item.tooltip !== undefined && item.tooltip !== null ?
        <span>
            <Tooltip title={item.tooltip}>
              <img src={TooltipImg} alt={"info icon"} width="25px" /> 
          </Tooltip>
          </span>
        : ""
        }
        </InputLabel>
        <Select
          style={{ height: 45 }}
          labelId="demo-simple-select-required-label1"
          id="demo-customized-select-native1"
          name={item.key}
          required={item.is_mandatory}
          value={initalState.field_details[originalIndex].value}
          label={item.display_title }
          disabled={!isEditAllowed}
          onChange={handleChange}
          input={<BootstrapInput />}
        >
          {countries.map((option) => {
          return(
          <MenuItem value={option.value}>{option.label}</MenuItem>
          )}
          )}
          
  
        </Select>
      </FormControl>
      </div>
      ) :
      item.currency !== '' ? 
        <CustomInput
        id={item.uuid}
        variant="outlined"
        type='currency'
        name={item.key}
        currency={item.currency}
        label={item.display_title }
        maxLength={256}
        tooltip = {item.tooltip}
        value={initalState.field_details[originalIndex].value}
        onChange={handleChange}
        disabled={!isEditAllowed}
        error={errors.field_details[originalIndex].value}
        errorText={
          initalState.field_details[originalIndex].value === "" ? "required": "Your currency is not in proper format"
        }
      /> :
      <CustomInput
        id={item.uuid}
        variant="outlined"
        type={item.field_type}
        name={item.key}
        label={item.display_title }
        maxLength={256}
        tooltip = {item.tooltip}
        required={item.is_mandatory}
        value={initalState.field_details[originalIndex].value}
        onChange={handleChange}
        disabled={!isEditAllowed}
        error={errors.field_details[originalIndex].value}
        errorText={
          initalState.field_details[originalIndex].value === "" ? "Required" : "Some error occured"
        }
      />}
      </div>)

    })}
      </div>

      ))}

      {!disabledNext ? (psp === "GBPRIMEPAY" ? !urlvalidation ? <div style={{ color: "red"}}> {t("Please fill atleast one of the following field.")} <br/>1. {("Merchant Instagram")} <br/> 2. {t("Merchant Facebook")} <br/> 3. {t("Merchant Website")} </div> : "" : "") : ""}
      <ButtonContainer style={{ textAlign: "left" }}>

        {psp === "GBPRIMEPAY" ? 
        <CustomButton
          text={t("Next")}
          variant="contained"
          disabled={urlvalidation ? disabledNext ? true: false : true}
          onClick={handleContinue}
        /> : <CustomButton
            text={t("Next")}
            variant="contained"
            disabled={disabledNext}
            onClick={handleContinue}
          />}
        
      </ButtonContainer>
    </>
  );
};

export default Step1;
