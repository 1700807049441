import React from "react";
import { Grid, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import Done from "../images/check.svg";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) =>
  createStyles({
    Main: {
      borderBottom: "1px solid #E4EAEE",
      textAlign: "center",
      margin: "4rem 0 3rem 0",
      height: "3rem",
      [theme.breakpoints.down("sm")]: {
        height: "3.5rem",
        margin: "3rem 0 2rem 0",
        whiteSpace: "nowrap",
        overflow: "auto",
      },
    },

    SubGrid: {
      [theme.breakpoints.down("sm")]: {
        //padding: "0 20px",
      },
    },

    active: {
      color: "#1B2125",
      paddingBottom: "1rem",
      fontWeight: 600,
      lineHeight: 1.1,
      cursor: "pointer",
      borderBottom: "1px solid #1B2125",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.9rem",
        //padding: "20px",
      },
    },
    disable: {
      color: "#D4D9DD",
      paddingBottom: "1rem",
      fontWeight: 600,
      lineHeight: 1.1,
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.9rem",
        //padding: "20px",
      },
    },
    boxdisabledactive: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      paddingTop: "0.5rem",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "1.3rem",
      },
    },
    checkimage: {
      height: "15px",
      paddingLeft: "10px",
      paddingBottom: "1rem",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "5px",
      },
    },
    boxActive: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: "0.5rem",
      //borderBottom: "1px solid #1B2125",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "1.3rem",
      },
    },
    boxDisabled: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: "0.5rem",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "1.3rem",
      },
    },
  })
);

export default function CustomTabs(props) {
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const { value, handleTab, page, pageCursor } = props;
  return (
    <Grid container className={classes.Main}>
      <Grid
        item
        xs={4}
        onClick={() => handleTab(0)}
        className={classes.SubGrid}
      >
        <Box className={value === 0 ? classes.boxActive : pageCursor > 0 ? classes.boxdisabledactive : classes.boxDisabled}>
          <Typography
            className={value === 0 ? classes.active : classes.disable}
            variant="h6"
            component="h6"
          >
            {t("Register")}
          </Typography>
          {page > 0 && (
            <img src={Done} alt={"check"} className={classes.checkimage} />
          )}
        </Box>
      </Grid>
      <Grid
        item
        xs={4}
        onClick={() => handleTab(1)}
        className={classes.SubGrid}
      >
        <Box className={value === 1 ? classes.boxActive : pageCursor > 1 ? classes.boxdisabledactive  : classes.boxDisabled}>
          <Typography
            className={value === 1 ? classes.active : classes.disable}
            variant="h6"
            component="h6"
          >
            {t("Upload")}
          </Typography>
          {page > 1 && (
            <img src={Done} alt={"check"} className={classes.checkimage} />
          )}
        </Box>
      </Grid>
      <Grid
        item
        xs={4}
        onClick={() => handleTab(2)}
        className={classes.SubGrid}
      >
        <Box className={value === 2 ? classes.boxActive : pageCursor > 2 ? classes.boxdisabledactive  : classes.boxDisabled}>
          <Typography
            className={value === 2 ? classes.active : classes.disable}
            variant="h6"
            component="h6"
          >
            {t("Review & Submit")}
          </Typography>
          {page > 2 && (
            <img src={Done} alt={"check"} className={classes.checkimage} />
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
