import React, {useState} from "react";
import CustomButton from "../../CustomButton";
import CustomDropzone from "../../CustomDropzone";
import ButtonContainer from "../../ButtonContainer";
import Cancel from "../../../images/cancel.svg";
import AccessTime from "../../../images/processing.svg";
import { linkFormator } from "../../../utils/formatter";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import Tooltip from "./Tooltip1";
import { display } from "@mui/system";

const Step2 = (props) => {
  const {
    setValue,
    setPage,
    setPageCursor,
    pageCursor,
    document_details,
    handleDocumentDetails,
    disableUpload,
    isEditAllowed,
    psp
  } = props;
  const { i18n, t } = useTranslation();
  setPageCursor(pageCursor >= 2 ? pageCursor : 2);
  let allHeadings1 = new Set();
  console.log("jsbncajb", document_details);
  document_details && document_details?.map((item, index) => (
    allHeadings1.add(item.section_heading)
  )) 
  

  const handleContinue = () => {
    setValue(2);
    setPage(2);
    {window.scrollTo(0, 0)}
  };
  const handleBack = () => {
    setValue(0);
  };
  return (
    document_details ?
    <>
    {Array.from(allHeadings1).map((heading, headingIndex) => (
        <div key={headingIndex}>
        {/* Render heading */}
        <Typography variant="h6" component="h6" style={{ textDecoration: "underline" }}>
          {t(heading)}
        </Typography>

      {/* Filter inputs with the current heading and render them */}
      {document_details.filter((item) => item.section_heading === heading).map((item, index) => (

        <div style={{ marginBottom: "2.5rem"}} key={index}>
        { item.multiple_upload ?
        <CustomDropzone
          handleDocumentDetails={handleDocumentDetails}
          uuid={item.uuid}
          name={item.key}
          title={item.display_title}
          is_mandatory={item.is_mandatory}
          description={item.tooltip}
          details={item.description}
          document_format={item.document_format}
          psp={psp}
          type={item.field_type}
          isEditAllowed={isEditAllowed}
          isMultipleUploadAllowed={item.multiple_upload}
          minSize={0}
          maxSize={5}
        />
        :
        item.multiple_upload ?
        <Tooltip item={item} />
        : ""
        }
        <div>
          <Grid
            container
            display={"flex"}
            alignItems={"center"}>
            {item.multiple_upload && item.multi_document_links.length > 0 ?  item.multi_document_links.map((file) => 
              file.split(".")[file.split(".").length-1] !== "pdf" ?
               <><img src={file} alt={file.fileName} width="150" style={{ border: "1px solid #bdbdbd", marginRight: "10px"}} /> <br/></>
               : "")
            : ""}
            </Grid>
            {item.multiple_upload && (
            
            <Grid
            container
            alignItems={"center"}
            justifyContent="space-between"
            style={{ marginTop: "1rem" }}
            >
            {item.multi_document_links.length > 0 && item.multi_document_links.map((file) => {
              let fileName1 = file.split('/');
              let fileName2 = fileName1[fileName1.length - 1];
            return(<>
            <Grid item display="flex" alignItems="center">
              {file.split(".")[file.split(".").length-1] === "pdf" ? (
                <img
                  src={require("../../../images/pdf.png")}
                  alt="pdficon"
                  style={{
                    height: "32px",
                    width: "auto",
                    paddingRight: "8px",
                  }}
                />
              ) :
              (
                <img
                  src={require("../../../images/imageicon.png")}
                  alt="imgicon"
                  style={{
                    height: "32px",
                    width: "auto",
                    paddingRight: "8px",
                  }}
                />
              )}{" "}
              {fileName2 ? fileName2 : t(item.key)}
            </Grid>
            <Grid item>
              <Box
                alignItems="center"
                display={"flex"}
                justifyContent="center"
              >
                {file === "" && (
                  <>
                    <img src={AccessTime} alt="timer" />
                    <Typography
                      style={{
                        color: "#F4AA74",
                        fontSize: "14px",
                        padding: "0 20px 0 5px",
                      }}
                    >
                      In process
                    </Typography>
                  </>
                )}
                <img
                  src={Cancel}
                  alt="cancel"
                  onClick={() => handleDocumentDetails("", item.uuid, "", true, item.multi_document_links.indexOf(file))}
                />
              </Box>
            </Grid>
            </>)
          })}
            </Grid>
          )}
          </div>




        {psp==="GBPRIMEPAY" && item.style === "disabled" ?
        <div style={{ marginBottom: "2.5rem", display:"none" }}>
          {item.link === "" && !item.multiple_upload ?
          <CustomDropzone
            handleDocumentDetails={handleDocumentDetails}
            uuid={item.uuid}
            name={item.key}
            title={item.display_title}
            is_mandatory={item.is_mandatory}
            description={item.tooltip}
            details={item.description}
            document_format={item.document_format}
            psp={psp}
            type={item.field_type}
            isEditAllowed={isEditAllowed}
            isMultipleUploadAllowed={item.multiple_upload}
            minSize={0}
            maxSize={5}
          />
          :
          !item.multiple_upload ?
          <Tooltip item={item} />
          : ""
          }
          <Grid
              container
              display={"flex"}
              alignItems={"center"}>
          {item.link !== "" && !item.multiple_upload ? item.link.split(".")[item.link.split(".").length-1] !== "pdf" ? <><img src={item.link} alt={item.fileName} width="150" style={{ border: "1px solid #bdbdbd"}} /> <br/></>: "" : ""}
            </Grid>
          {item.link !== "" && !item.multiple_upload && (
            <Grid
              container
              alignItems={"center"}
              justifyContent="space-between"
              style={{ marginTop: "1rem" }}
            >
              <Grid item display="flex" alignItems="center">
                {item.link.split(".")[item.link.split(".").length-1] === "pdf" ? (
                  <img
                    src={require("../../../images/pdf.png")}
                    alt="pdficon"
                    style={{
                      height: "32px",
                      width: "auto",
                      paddingRight: "8px",
                    }}
                  />
                ) :
                 (
                  <img
                    src={require("../../../images/imageicon.png")}
                    alt="imgicon"
                    style={{
                      height: "32px",
                      width: "auto",
                      paddingRight: "8px",
                    }}
                  />
                )}{" "}
                {item.fileName ? item.fileName : t(item.key)}
              </Grid>
              <Grid item>
                <Box
                  alignItems="center"
                  display={"flex"}
                  justifyContent="center"
                >
                  {item.link === "" && (
                    <>
                      <img src={AccessTime} alt="timer" />
                      <Typography
                        style={{
                          color: "#F4AA74",
                          fontSize: "14px",
                          padding: "0 20px 0 5px",
                        }}
                      >
                        In process
                      </Typography>
                    </>
                  )}
                  <img
                    src={Cancel}
                    alt="cancel"
                    onClick={() => handleDocumentDetails("", item.uuid)}
                  />
                </Box>
              </Grid>
            </Grid>)}
        </div>
        :
        <div style={{ marginBottom: "2.5rem" }}>
          {item.link === "" && !item.multiple_upload ?
          <CustomDropzone
            handleDocumentDetails={handleDocumentDetails}
            uuid={item.uuid}
            name={item.key}
            title={item.display_title}
            is_mandatory={item.is_mandatory}
            description={item.tooltip}
            details={item.description}
            document_format={item.document_format}
            type={item.field_type}
            psp={psp}
            isEditAllowed={isEditAllowed}
            isMultipleUploadAllowed={item.multiple_upload}
            minSize={0}
            maxSize={5}
          />
          :
          !item.multiple_upload ?
          <Tooltip item={item} />
          : ""
          }
          <Grid
              container
              display={"flex"}
              alignItems={"center"}>
          {item.link !== "" && !item.multiple_upload ? item.link.split(".")[item.link.split(".").length-1] !== "pdf" ? <><img src={item.link} alt={item.fileName} width="150" style={{ border: "1px solid #bdbdbd"}} /> <br/></>: "" : ""}
            </Grid>
          {item.link !== "" && !item.multiple_upload && (
            <Grid
              container
              alignItems={"center"}
              justifyContent="space-between"
              style={{ marginTop: "1rem" }}
            >
              <Grid item display="flex" alignItems="center">
                {item.link.split(".")[item.link.split(".").length-1] === "pdf" ? (
                  <img
                    src={require("../../../images/pdf.png")}
                    alt="pdficon"
                    style={{
                      height: "32px",
                      width: "auto",
                      paddingRight: "8px",
                    }}
                  />
                ) :
                 (
                  <img
                    src={require("../../../images/imageicon.png")}
                    alt="imgicon"
                    style={{
                      height: "32px",
                      width: "auto",
                      paddingRight: "8px",
                    }}
                  />
                )}{" "}
                {item.fileName ? item.fileName : t(item.key)}
              </Grid>
              <Grid item>
                <Box
                  alignItems="center"
                  display={"flex"}
                  justifyContent="center"
                >
                  {item.link === "" && (
                    <>
                      <img src={AccessTime} alt="timer" />
                      <Typography
                        style={{
                          color: "#F4AA74",
                          fontSize: "14px",
                          padding: "0 20px 0 5px",
                        }}
                      >
                        In process
                      </Typography>
                    </>
                  )}
                  <img
                    src={Cancel}
                    alt="cancel"
                    onClick={() => handleDocumentDetails("", item.uuid)}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
        </div>
        }
        </div>
      ))}
      </div>

      ))}
      {/* <CustomDropzone title={'Company Registration Certificate'} /> */}
      {/* <div key={item.uuid} style={{ justifyContent: 'space-between', alignItems: 'center', display: 'flex' }}>
                        <div style={{ alignItems: 'center', display: 'flex', fontSize: "14px" }}>  {item.type === 'jpg' || item.type === 'png' ? <img src={require('../../../images/imageicon.png')} style={{ height: '16px', marginRight: "8px" }} /> : <img src={require('../../../images/pdf.png')} style={{ height: '16px' }} />} {item.fileName ? item.fileName : linkFormator(item.link)} </div>
                        <div style={{ display: "flex", alignItems: "center", }}>
                            <img src={AccessTime} />
                            <p style={{ color: "#F4AA74", fontSize: "14px", marginRight: "10px" }}>In process</p>
                            <img src={Cancel}
                                onClick={() => handleDocumentDetails('', item.uuid)}
                            />
                        </div>
                    </div> */}
      <ButtonContainer style={{ textAlign: "left" }}>
        <CustomButton
          text={t("Next")}
          variant="contained"
          disabled={disableUpload}
          onClick={handleContinue}
        />
        <CustomButton text={t("Back")} variant={"text"} onClick={handleBack} />
      </ButtonContainer>
    </>
    :
    <>
    <p>No documents left.</p>
    <ButtonContainer style={{ textAlign: "left" }}>
        <CustomButton
          text={t("Next")}
          variant="contained"
          disabled={disableUpload}
          onClick={handleContinue}
        />
        <CustomButton text={t("Back")} variant={"text"} onClick={handleBack} />
      </ButtonContainer>
    </>
  );
};

export default Step2;
