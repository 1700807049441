import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
    createStyles({
        ButtonContainer: {
            textAlign: 'center',
            marginTop: '1rem',
            [theme.breakpoints.up('sm')]: {
                textAlign: 'right',
                marginTop: '1rem',
            },
        }
    }));

export default function ButtonContainer(props) {
    const classes = useStyles();
    return (
        <div className={classes.ButtonContainer} style={props.style}> {props.children}</div>
    );
}
