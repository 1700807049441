import * as React from "react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import {  FormControl, Select, InputBase, Checkbox } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Vietnam from "../images/vietnam_flag.png";
import English from "../images/uk.png";
import Korea from '../images/kr.png';
import Thailand from "../images/flag-thai.png";
import Singapore from "../images/sgp.png";
import Indonesia from "../images/id.png";
import Philippines from "../images/ph.png";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Mail from "../images/email.svg";
import Call from "../images/call.svg";
import Logo from "../images/portone_logo.png";
import { Switch } from '@mui/material';
import request from "../utils/request";
import { GET_KYC_SUBMISSION } from "../utils/constants";

const Navbar_Brand = styled("img")(({ theme }) => ({
  height: "2rem",
}));

const Image = styled("img")(({ theme }) => ({
  maxWidth: "30px",
}));

const CustomAppBar = styled(AppBar)(({ theme }) => ({
  boxShadow: "0px 20px 80px 0px #00000029",
  backgroundColor: "white",
  color: "black",
  height: "5rem",
  justifyContent: "center",
  padding: "0 7rem",
  [theme.breakpoints.down("sm")]: {
    padding: "0rem 1rem",
  },
}));

const FlagImage = styled("img")(({ theme }) => ({
  width: "20px",
  height: "15px",
  marginRight:"5px",
  border:"1px solid black",
  //   marginTop: "10px",
  [theme.breakpoints.down("sm")]: {
    width: "20px",
    border:"1px solid black",
  },
}));

const Dropdown = styled(Select)(({ theme }) => ({
  float: "right",
  height:"40px",
  //marginRight:"0rem",
  //padding: "5px",
  //border: "1px solid grey",
  borderRadius: "4px",
  right:"80px",
  '& .MuiSelect-select':{
    display:"flex",
    alignItems:"center",
    

  },
  '.MuiSvgIcon-root':{ width:"20px", height:"20px", top:"7px", color:"#707070"},
  [theme.breakpoints.down("lmd")]: {
    right:"20px",
    marginBottom:"20px"
  },

  [theme.breakpoints.down("md")]: {
    float: "right",
    padding: "5px",
    border: "1px solid grey",
    borderRadius: "4px",
    //marginRight:"1rem",
    right:"0",
    '.MuiSvgIcon-root':{ width:"20px", height:"20px", top: "7px", color:"#707070;"},
  },
}));

const useStyles = makeStyles((theme) =>
  createStyles({
    langDropdown:{
      [theme.breakpoints.up("sm")]: {
        zIndex: "999999999",
      },
      [theme.breakpoints.down("sm")]: {
        //position: "absolute",
        zIndex: "999999999",
        right: "3%",
        top: "8%",
      }
    
        
      },
      
  })
);

export default function Appbar(props) {
  const { i18n, t } = useTranslation();
  const {setChecked, setUnfilledResponse, checked, otp, unfilledResponse, setVerified} = props;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let country = urlParams.get('country');
  const lang = country === 'VND' ? 'vi' : country === "THB" ? 'th' : country === 'KRW' ? 'kr' : country === 'PHP' ? 'ph' : 'en';
  const [language1, setLanguage1] = useState(lang);
  useEffect(() => {
    i18n.changeLanguage(lang).catch(error => {
      console.error('Error changing language:', error);
    });
  }, [lang]);
  const classes = useStyles();
  const [showTooltip, setShowTooltip] = useState(false);
  //const [showTooltipmob, setShowTooltipmob] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  //   const handleProfileMenuOpen = (event) => {
  //     setAnchorEl(event.currentTarget);
  //   };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleChange = (event) => {
    const newChecked = event.target.checked;
    setChecked(newChecked); 
  };

  useEffect(() => {
    if(checked){
      let key = window.location.pathname.split("/");
      const queryString = window.location.search;
      let psp = ""
      const urlParams = new URLSearchParams(queryString);
      const category1 = urlParams.get('category');
      const environment = urlParams.get('environment') ? urlParams.get('environment') : 'live';
      let paymentChannel = (category1 === "" || category1 === null) ? psp : urlParams.get('paymentChannel');
      let country = psp === "GBPRIMEPAY" && (category1 === "" || category1 === null) ? "TH" : "GLOBAL";
      request
        .get(GET_KYC_SUBMISSION, key[key.length - 1], country, paymentChannel, environment, category1, otp, checked)
        .then((res) => {
          console.log("testing...", res);
          if(res.data.status_reason === "OTP_EXPIRED"){
            setVerified(false)
          }
        setUnfilledResponse(res);
        console.log(res);
        console.log(unfilledResponse);
        }).catch((error) => {
          if(error.data.status_reason === "OTP_EXPIRED"){
            setVerified(false);
          }
          console.log(error);
        })
    }

  },[checked])

  

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  function changeLanguage(e) {
    setLanguage1(e.target.value);
    i18n.changeLanguage(e.target.value);
  }

  const menuId = "primary-search-account-menu";

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={0} color="error">
            <Image src={Message} alt="message" />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem> */}
      <Tooltip
        disableFocusListener
        title="Mail: contact@portone.cloud"
        open={showTooltip}
        onOpen={() => setShowTooltip(true)}
        onClose={() => setShowTooltip(false)}
      >
        <MenuItem>
          <IconButton
            onClick={() => setShowTooltip(!showTooltip)}
            size="large"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <Badge badgeContent={0} color="error">
              <Image src={Mail} alt="mail" />
            </Badge>
          </IconButton>

          <p>Contact</p>
        </MenuItem>
      </Tooltip>
      {/* <Tooltip
        disableFocusListener
        title="Phone: +91XXXXXXX "
        open={showTooltipmob}
        onOpen={() => setShowTooltipmob(true)}
        onClose={() => setShowTooltipmob(false)}
      >
        <MenuItem>
          <IconButton
            onClick={() => setShowTooltipmob(!showTooltipmob)}
            size="large"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <Image src={Call} alt="call" />
          </IconButton>

          <p>Contact</p>
        </MenuItem>
      </Tooltip> */}

      {/* <MenuItem>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <Image src={Help} alt="call" />
        </IconButton>
        <p>Need any help?</p>
      </MenuItem> */}
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CustomAppBar position="fixed">
        <Toolbar style={{ padding: 0 }}>
          <Navbar_Brand src={Logo} alt="logo" />
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'flex', md: 'flex' }, alignItems: 'center' }}>
        {urlParams.get('category') === "CROSS_BORDER" ?
        <>
          <FormControl
              fullWidth
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'right',
                border: '1px solid #FFFFFF4D',
                borderRadius: '4px',
                
              }}
            >
              <Dropdown
                id="mySelect"
                className={classes.langDropdown}
                value={
                  language1 && ['en', 'vi', 'kr', 'th', 'sg', 'id', 'ph'].includes(language1)
                    ? language1
                    : 'en'
                }
                onChange={changeLanguage}
              >
                <MenuItem value="en">
                  <FlagImage src={English} alt="English" /> English
                </MenuItem>
                <MenuItem value="kr">
                  <FlagImage src={Korea} alt="Korean" /> 한국인
                </MenuItem>
                {/* <MenuItem value="vi">
                  <FlagImage src={Vietnam} alt="Vietnamese" /> Tiếng Việt
                </MenuItem>
                <MenuItem value="th">
                  <FlagImage src={Thailand} alt="Thai" /> ไทย
                </MenuItem>
                <MenuItem value="sg">
                  <FlagImage src={Singapore} alt="Singapore" /> Singapore
                </MenuItem>
                <MenuItem value="id">
                  <FlagImage src={Indonesia} alt="Indonesian" /> Bahasa Indonesia
                </MenuItem>
                <MenuItem value="ph">
                  <FlagImage src={Philippines} alt="Philippines" /> Pilipino
                </MenuItem> */}
              </Dropdown>
            </FormControl>
            <p>UnfilledDocs</p>
            <Switch
              checked={checked}
              onChange={handleChange}
            />
            
          </>
            : ""}
            <Box sx={{ display: { xs: 'none', md:"flex" } }}>
              <Tooltip title="Mail: contact@portone.cloud">
                <IconButton
                  size="medium"
                  edge="end"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  color="inherit"
                  style={{ fontSize: 'medium' }}
                >
                <Image src={Mail} alt="mail" />
                </IconButton>
              </Tooltip>
            </Box>
            
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </CustomAppBar>
      {renderMobileMenu}
    </Box>
  
  );
}
