const EmailRegex = /^(?:([a-z+\d\.-_]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,6})?)?$/i;
const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()_=~])[A-Za-z\d#@$!%*?&^()_=~]{8,}$/;
const IsRequiredRegex = /^\s*$/;
const UrlRagex = /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/;

export const validateEmail = email => !EmailRegex.test(email);
export const validatePassword = password => !PasswordRegex.test(password);
export const validateUrl = url => url === "" ? false : !UrlRagex.test(url);
export const validateRequired = value => IsRequiredRegex.test(value);


export const validator = (type, value) => {
    switch(type){
        case "email":
            return validateEmail(value);
        
        case "password":
            return validatePassword(value);

        case "merchant_facebook":
            return validateUrl(value);

        case "merchant_instagram":
            return validateUrl(value);

        case "merchant_website":
            return validateUrl(value);

        default:
            return false;
    }
}