import React from "react";
import { Button, Typography } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from "react-i18next";

export default function CustomButton(props) {
  const { variant, style, onClick, disabled, text } = props;
  const { i18n, t } = useTranslation();
  return (
    <Tooltip title={disabled && text !== "Submit" ? t("You have missed any mandatory field, please fill it in.") : ""} placement={'bottom-end'}> 
    <span>
    <Button
      style={
        variant === "contained"
          ? {
              ...style,
              backgroundColor: disabled ? "#D3D3D3" : "#000",
              color: disabled ? "#000000" : "#ffffff",
              padding: "0.5rem 3rem",
              margin: "2rem 0",
              textTransform: "none",
              cursor: disabled ? "not-allowed" : "pointer",
            }
          : {
              ...style,
              color: "#000",
              padding: "0.5rem 3rem",
              textTransform: "none",
              margin: "2rem 0",
              cursor: disabled ? "not-allowed" : "pointer",
            }
      }
      onClick={onClick}
      variant={variant ? variant : "contained"}
      disabled={disabled}
    >
      {" "}
      {text}
    </Button>
    </span>
    </Tooltip>
  );
}
