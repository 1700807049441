import React from "react";
import { styled } from "@mui/material/styles";
import CustomButton from "../../CustomButton";
import { Grid, Typography, Box } from "@mui/material";
import Checked from "../../../images/Checked.svg";
import { useTranslation } from "react-i18next";

const Check = styled("img")(({ theme }) => ({
  // height: "5rem",
  width: "auto",
}));

const Step4 = () => {
  const { i18n, t } = useTranslation();
  return (
    <Grid container textAlign="center">
      <Grid item xs={12}>
        <Check src={Checked} />
        <Typography variant="h5" component="h6" style={{ color: "#03A62C" }}>
          {t("Your KYC document has")} <br /> {t("been successfully")} <br /> {t("submitted")}
        </Typography>
      </Grid>
      {/* <Grid item xs={12} marginTop="1rem">
                <CustomButton text="Done" variant="contained" style={{
                    padding: "1rem 10rem",
                    fontSize: "large",
                    margin: "2rem 0",
                }} />
            </Grid> */}
    </Grid>
  );
};

export default Step4;
