import { Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Alert } from "@mui/material";
import { useDropzone } from "react-dropzone";
import Drag from "../images/drag.png";
import Cancel from "../images/cancel.svg";
import AccessTime from "../images/processing.svg";
import request from "../utils/request";
import { UPLOAD_KYC } from "../utils/constants";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { color } from "@mui/system";
import { useTranslation } from "react-i18next";


const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};


export default function CustomDropzone(props) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const { type, handleDocumentDetails, uuid, document_format, is_mandatory, isEditAllowed, psp, isMultipleUploadAllowed, name } = props;
  const [nFiles, setFiles] = useState([]);
  const { i18n, t } = useTranslation();
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    disabled: !isEditAllowed,
    accept:
      document_format === "jpg,png,pdf"
        ? `image/jpeg,image/png,application/pdf`
        : document_format === "jpg,png,pdf,doc" 
        ?  `image/jpeg,image/png,application/pdf,application/msword`
        : document_format === "jpg"
        ? `image/jpeg`
        : document_format === "jpg,png"
        ? `image/jpeg,image/png`
        : document_format === "png"
        ? `image/png`
        : "application/pdf",
    maxSize: psp === "GBPRIMEPAY" ? 5242880 : 10000000,
    multiple: isMultipleUploadAllowed
  });

  const acceptedFileItems = acceptedFiles.map((file) => <p>Uploaded</p>);

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {errors.map((e) => (
        <span key={e.code}>{e.message}</span>
      ))}
    </li>
  ));

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );


  const getBase64 = (file) => {
    return new Promise(resolve => {
      //let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
      //console.log(fileInfo);
    });
  };


  useEffect(() => {
    if (acceptedFiles.length === 1) {
      setFiles(acceptedFiles);
      const formData = new FormData();
      formData.append("file", acceptedFiles[0]);
      let key = window.location.pathname.split("/");
      if(type === 'doc_link')
        request
          .postMultipart(UPLOAD_KYC, formData, key[key.length - 1])
          .then((res) => {
            if (res.status === 200) {
              setResponseMessage("");
              handleDocumentDetails(res.data.link, uuid, acceptedFiles[0].name, isMultipleUploadAllowed);
            }
            else {
              setResponseMessage(res.data.message);
            }
          });
      else{
          let file1 = acceptedFiles[0];
          getBase64(file1)
            .then(result => {
              file1["base64"] = result;
              handleDocumentDetails(file1["base64"], uuid, acceptedFiles[0].name, isMultipleUploadAllowed);
            })
            .catch(err => {
              console.log(err);
            });
          
        }
    } else{
      // Iterate over acceptedFiles array
      acceptedFiles.forEach((file) => {
        const formData = new FormData();
        formData.append("file", file);
        let key = window.location.pathname.split("/");
        if (type === "doc_link")
          request.postMultipart(UPLOAD_KYC, formData, key[key.length - 1]).then((res) => {
            if (res.status === 200) {
              setResponseMessage("");
              handleDocumentDetails(res.data.link, uuid, file.name, isMultipleUploadAllowed);
            } else {
              setResponseMessage(res.data.message);
            }
          });
        else {
          getBase64(file)
            .then((result) => {
              file["base64"] = result;
              handleDocumentDetails(file["base64"], uuid, file.name, isMultipleUploadAllowed);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });

    }
  }, [acceptedFiles]);
  const files = nFiles.map((file, i) => {
    <div
      key={file.path}
      style={{
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
      }}
    >
      <div style={{ alignItems: "center", display: "flex" }}>
        {" "}
        {/* {file.type == "image/jpeg" || file.type == "image/png" ? (
          <img
            src={require("../images/imageicon.png")}
            alt="imgicon"
            style={{ height: "36px", marginRight: "8px" }}
          />
        ) : (
          <img
            src={require("../images/pdf.png")}
            alt="pdficon"
            style={{ height: "36px" }}
          />
        )}{" "} */}
        {file.name}{" "}
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img src={AccessTime} alt="timer" />
        <p style={{ color: "#F4AA74", fontSize: "14px", marginRight: "4px" }}>
          In process
        </p>
        <img src={Cancel} alt="cancel" onClick={() => remove(i)} />
      </div>
    </div>
});

  const remove = (file) => {
    const newFiles = [...files]; // make a var for the new array
    newFiles.splice(file, 1); // remove the file from the array
    setFiles(newFiles); // update the state
    handleDocumentDetails("", uuid);
  };

  return (
    <>
    {responseMessage !== "" && (
        <Alert
          severity="error"
          onClose={() => {
            setResponseMessage("");
          }}
        >
          {responseMessage}
        </Alert>
      )}

        <Typography
          style={{ color: "#000", opacity: "0.6", marginBottom: "1rem" }}
        >
        <div style={{ display: "flex", justifyContent:"start", alignItems: "center" }}>
        {is_mandatory && <><span class="text-danger" style={{ fontSize: "25px"}}>* </span></>}  <span style={{ fontWeight: 700}}>{t(name)}</span>  {props.description && (
        <Tooltip
         
          disableFocusListener
          title={t(props.description)}
          open={showTooltip}
          arrow={true}
          placement={'bottom-end'}
          onOpen={() => setShowTooltip(true)}
          onClose={() => setShowTooltip(false)}
        ><InfoOutlinedIcon onClick={() => setShowTooltip(!showTooltip)}/></Tooltip>)}
        </div>
        
        
        <Typography variant="p" component="p" style={{ fontSize: "12px", marginTop:"8px" }}>
          {t(props.details)}
        </Typography>
        </Typography>
      <section className="container" style={{ cursor: "pointer" }}>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <img src={Drag} alt={"drag"} style={{ height: 42, width: 45 }} />
          <p>
            {t("Drag and Drop or")} <u>{t("Browse")} </u>
            <br />
            {t("Please upload")} {`${document_format}`} {t("file")}.
          </p>
        </div>
        <aside>
          {/* <ul>{acceptedFileItems}</ul> */}
          <ul style={{ color: "red", backgroundColor: "#ffefef" }}>{fileRejectionItems}</ul>
        </aside>
        {/* <aside style={{ margin: '2rem 0' }}>
                    {files}
                </aside> */}
      </section>
    </>
  );
}
